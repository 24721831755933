
@import url('https://fonts.googleapis.com/css2?family=M+PLUS+1p&display=swap');
* {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    font-family: 'M PLUS 1p', sans-serif;
  }
  
  #gradient-canvas {
    width:100%;
    height:100%;
    --gradient-color-1: #007bff; 
    --gradient-color-2: #00ffd5; 
    --gradient-color-3: #00d5ff;  
    --gradient-color-4: #ffffff;
  }

  .spin {
    animation: spin 1s linear infinite;
  }
  
  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }